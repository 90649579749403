import { app } from "../../app.module";
import { SESSION_AO_CODE_KEY } from "../../configuration";
import { IAssociateAdditionalRegistration, IDataContext } from "../../services/dataContext";
import { ILocationService, IRootScopeService, IWindowService } from "angular";
import { ILocalStorageService } from "angular-local-storage";
import templateUrl from "./verify.html";
import "./verify.less";
import { GetDocumentVerificationTokenResponse } from "@/app/models/api";
import { IToastrService } from "angular-toastr";

const controllerId = "accountOwnerVerify";

app.controller(controllerId, accountOwnerVerify);

app.component(`${controllerId}Component`, {
    templateUrl: templateUrl,
    controller: controllerId,
    controllerAs: "vm",
    bindings: {
        dvsContext: "="
    }
});

function accountOwnerVerify(
    $location: ILocationService,
    localStorageService: ILocalStorageService,
    $route: angular.route.IRouteService,
    $window: IWindowService,
    $rootScope: IRootScopeService,
    dataContext: IDataContext,
    toastr: IToastrService
) {
    "ngInject";
    const vm = this;

    vm.isThisYou = null;
    vm.hasExistingLogin = null;

    vm.busy = false;

    vm.dvs = $route.current.locals.dvsContext as GetDocumentVerificationTokenResponse;

    vm.associateAdditionalRegistrationResponse = null;
    vm.verificationInProgress = false;

    vm.startVerification = () => {
        vm.verificationInProgress = true;
        vm.greenIdUi.show(vm.dvs.customerId, vm.dvs.apiCode, vm.dvs.token);
    };

    const getSessionToken = () => {
        return localStorageService.get(SESSION_AO_CODE_KEY);
    };

    vm.onLogin = () => {
        if (vm.busy) return;

        vm.busy = true;
        vm.hasAssociateRegError = false;
        vm.associateRegError = null;

        dataContext.associateAdditionalRegistration(getSessionToken()).then(
            function (data: IAssociateAdditionalRegistration) {
                vm.busy = false;
                vm.associateAdditionalRegistrationResponse = data;
                vm.associateRegError = data.errorMessage;

                if (data.valid) {
                    $location.path("/addStudent");
                } else if (data.userNotAccountOwner) {
                    vm.startVerification();
                }
            },
            function (data) {
                vm.associateAdditionalRegistrationResponse = data;
                vm.busy = false;
            }
        );
    };

    vm.cancel = () => {
        $location.path("/landing");
        $rootScope.$apply();
    };

    const verify = (status: string) => {
        if (status === "VERIFIED" || status === "VERIFIED_WITH_CHANGES") {
            $location.path("/registration/account-owner/register").search({ userId: vm.dvs.verificationId });
            $rootScope.$apply();
        } else {
            const dialog = document.getElementById("id-not-verified-dialog") as HTMLDialogElement;
            dialog.showModal();
            scroll({ top: 0 });
            vm.cancel();
        }
    };

    const onSessionComplete = (verificationToken: string, status: string) => {
        verify(status);
        vm.verificationInProgress = false;
    };

    vm.notEnoughIdDialogVisible = false;
    vm.notEnoughId = () => {
        vm.notEnoughIdDialogVisible = true;
        const dialog = document.getElementById("not-enough-id-dialog") as HTMLDialogElement;
        dialog.showModal();
        scroll({ top: 0 });
    };
    vm.cancelNotEnoughId = () => {
        vm.notEnoughIdDialogVisible = false;
        const dialog = document.getElementById("not-enough-id-dialog") as HTMLDialogElement;
        dialog.close();
    };

    vm.skipVerification = () => {
        $location.path("/registration/account-owner/register").search({ userId: vm.dvs.verificationId });
        $rootScope.$apply();
        vm.verificationInProgress = false;
    };

    const isScriptLoaded = (scriptUrl: string): boolean => {
        const scripts = document.getElementsByTagName("script");
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute("src") != null && scripts[i].getAttribute("src").includes(scriptUrl)) {
                return true;
            }
        }
        return false;
    };

    const addScript = (baseUrl: string, scriptUrl: string): Promise<void> => {
        if (isScriptLoaded(scriptUrl)) {
            return Promise.resolve();
        }

        const script = document.createElement("script");
        script.src = baseUrl + "/" + scriptUrl;
        script.type = "text/javascript";
        document.body.appendChild(script);

        return new Promise<void>(resolve => {
            script.onload = () => {
                resolve();
            };
        });
    };

    const isStylesheetLoaded = (styleUrl: string): boolean => {
        const scripts = document.getElementsByTagName("link");
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute("href") != null && scripts[i].getAttribute("href").includes(styleUrl)) {
                return true;
            }
        }
        return false;
    };

    const addStylesheet = (baseUrl: string, styleUrl: string): Promise<void> => {
        if (isStylesheetLoaded(styleUrl)) {
            return Promise.resolve();
        }

        const style = document.createElement("link");
        style.href = baseUrl + "/" + styleUrl;
        style.type = "text/css";
        style.rel = "stylesheet";
        style.media = "all";
        style.onload = undefined;
        document.head.appendChild(style);

        return new Promise<void>(resolve => {
            style.onload = () => {
                resolve();
            };
        });
    };

    vm.$onInit = async function () {
        // Add green id scripts and styles documented at https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/1495793713/Registering+and+Verifying+an+Individual+via+Document+Validation+and+Facial+Biometrics
        await addScript(vm.dvs.dvsHost, "df/javascripts/greenidConfig.js");
        vm.greenIdConfig = $window["greenidConfig"];

        await addScript(vm.dvs.dvsHost, "df/javascripts/greenidui.min.js");
        vm.greenIdUi = $window["greenidUI"];

        await addScript(vm.dvs.dvsHost, "df/javascripts/libs/jquery-3.5.1.min.js");
        await addStylesheet(vm.dvs.dvsHost, "df/assets/stylesheets/greenid.css");

        // See: https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/365002999/Continuing+the+Verification+of+Previously+Registered+Individuals
        // Users are registered by an API call in the back end, so we use the "continue" flow
        vm.greenIdUi.setup({
            environment: vm.dvs.dvsEnvironment,
            frameId: "greenid-div",
            debug: false,
            // Callbacks explained here: https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/8880338/Registering+and+Verifying+New+Individuals#Javascript-Setup-for-Registering-a-New-Person
            sessionCompleteCallback: onSessionComplete
        });

        const introText = `<p class="lead">To verify your identity, you’ll need to match 100 points of ID.</p><p class="lead">Get started with your first ID source below.</p>`;
        vm.greenIdConfig.setOverrides({
            enable_save_and_complete_later: false,
            intro_title: "<h1>Identity Document Verification</h1>",

            dl_short_title: "Driver's licence (60)",
            nswregodvs_title: "Driver's licence (60)",
            qldregodvs_title: "Driver's licence (60)",
            saregodvs_title: "Driver's licence (60)",
            vicregodvs_title: "Driver's licence (60)",
            waregodvs_title: "Driver's licence (60)",
            actregodvs_title: "Driver's licence (60)",
            ntregodvs_title: "Driver's licence (60)",
            tasregodvs_title: "Driver's licence (60)",

            medicaredvs_short_title: "Medicare card (40)",
            medicaredvs_title: "Medicare card (40)",
            passportdvs_short_title: "Australian passport (50)",
            passportdvs_title: "Australian passport (50)",
            visadvs_short_title: "Australian visa (40)",
            visadvs_title: "Australian visa (40)",
            citizenshipcertificatedvs_short_title: "Australian citizenship certificate (50)",
            citizenshipcertificatedvs_title: "Australian citizenship certificate (50)",
            birthcertificatedvs_short_title: "Birth certificate (50)",
            birthcertificatedvs_title: "Birth certificate (50)",
            marriagecertificatedvs_short_title: "Marriage certificate (40)",
            marriagecertificatedvs_title: "Marriage certificate (40)",
            changeofnamecertificatedvs_short_title: "Change of name certificate (40)",
            changeofnamecertificatedvs_title: "Change of name certificate (40)",

            intro_introText0: introText,
            intro_introText1: introText,
            intro_introText2: introText
        });
        // For testing, you can use these values: https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/8880678/Data+Testing
        // Driver's license: the card number is the same as license number, with 2 more digits appended
    };
}
